import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './portada.css'
import {} from 'react-bootstrap';
import { Container } from 'react-bootstrap';

export default function Portada(){
    const red = {
        color: "#DB3743"
    }
 return(
    <>
        <header className="masthead" id="portadaInicio">
            <Container>
                <div class="col-lg-12 col-sm-12 col-md-12">
                    <p className="pTituloInicio"><br></br>
                        Somos para ti,<br></br> 
                        <b style={red} >somos para todos.</b>
                    </p>
                    <p className="SubTitulo">#TuSindicato</p>
                </div>
            </Container>
        </header> 
        
    </>

 );
}
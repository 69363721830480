import React from "react";
import Menu from './../components/menu';
import Portada from './../components/portada';
import Somos from './../components/somos';
import Meta from './../components/meta';
import Vision from './../components/vision';
import Propuesta from './../components/propuesta';
import Planes from './../components/planes';
import Organizacion from './../components/organizacion';
import Contacto from './../components/contacto';
import Footer from './../components/footer';


const Home = () =>{
    return(
        <>
              <Menu />
              <Portada />
              <Somos />
              <Meta />
              <Vision />
              <Propuesta />
              <Planes />
              <Organizacion/>
              <Contacto/>
              <Footer/>
        </>
    );
}

export default Home;
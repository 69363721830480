import Home from './pages/home';
import Aviso from './pages/aviso';
import Integrantes from './pages/integrantes';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";


function App() {
  return (
    <>
    <Router>
      <Switch>
       <Route path="/integrantes" exact component={Integrantes}/>
       <Route path="/aviso" exact component={Aviso}/>
       <Route path="/" exact component={Home} />
      </Switch>
    </Router>
    </>
  );
}

export default App;

import React from "react";
import Integra from "../components/integrantes";
import Menu from "../components/menua";
import Footer from "../components/footer";

const Aviso = () => {
    return(
        <>
            <Menu />
            <Integra />
            <Footer />
        </>
    );
}

export default Aviso;
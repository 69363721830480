import React from "react";
import Privacidad from "../components/privacidad";
import Menu from "../components/menua";
import Footer from "../components/footer";

const Aviso = () => {
    return(
        <>
            <Menu />
            <Privacidad />
            <Footer />
        </>
    );
}

export default Aviso;
import React from "react";
import {Container,Row,Image} from "react-bootstrap";
import {
    Link
  } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './somos.css'
import imagen from './assets/imgsomos.png';


export default function Somos(){
    const negritas = {
        color: "#494949"
    }
    const rojo = {
        color: "#DB3743"
    }
    const butonfondo = {
        "background-color": "#DB3743",
        "border-color": "#DB3743",
        "font-family" : 'Poppins-Regular',
        "color" : "white"
    }
    return(
        <section className="sectio" id="Somos">
            <Container>
                <Row>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h4 className="Somos">¿Quiénes somos?</h4>
                        <h3 className="h3somos">
                             Un sindicato nacional mexicano <b style={negritas}>de la industria de alimentos, </b>
                              de avanzada y con visión continental.
                        </h3>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="parrafo">
                            Con una filosofía sindical que <b>procura la empatía gremial y crea vínculos con colaboradores y 
                            trabajadores</b> de todo el Continente Americano.<br></br>
                            Nuestro radio de acción abarca todos los procesos de la industria de alimentos: agricultura, pesca, logística,
                            procesadoras, puntos de venta y otras más. <b style={rojo}>  Nos definimos de avanzada porque creemos en la evolución del
                            sindicalismo y su transformación a través de una relación alternativa y positiva entre empresa, trabajadores y 
                            sindicato.</b>
                        </p>
                    </div>
                </Row>
                <Row>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <br></br>
                        <Link className="btn btn-info" style={butonfondo} to="/integrantes">Ver Secretarios</Link>
                    </div>
                </Row>
                <Row>
                    <Image src={imagen} className="img-fluid"/>
                </Row>
                <Row>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <h4 className="parrafoRojo">
                            Somos multinacionales porque creamos programas continentales a través de alianzas con UNIONS,
                            otros sindicatos del continente, así como cooperación con organizaciones de trabajadores y ONGs.
                        </h4>
                    </div>
                </Row>
            </Container>
        </section>
    );
}

import React from "react";
import {Container,Row,Image} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './propuesta.css';
import imagen from './assets/planesicon.png';
import imagen2 from './assets/prestacionesicon.png';
import imagen3 from './assets/imgplanes.png';


export default function Propuesta(){
    return(
        <>
            <section id="Propuestas">
                <Container>
                    <Row>
                        <div className="col-lg-12 col-md-12 col-sm-12" id="divPropuesta">
                            <h2 className="propuesta">
                                Propuestas y organización.
                            </h2>
                            <p className="pPropuesta">
                                Se desarrollan apegados a la ley federal del trabajo y las necesidades de nuestros agremiados;
                                estos son fundamentales y generan una dinámica y un ambiente laboral proactivo y positivo.
                            </p>
                        </div>
                    </Row>
                    <Row id="rowPropuesta">
                        <div className="col-lg-6 col-md-6 col-sm-12" id="divImg" >
                            <Image src={imagen} className="img-fluid" />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12" >
                            <Image src={imagen2} className="img-fluid" />
                        </div>
                    </Row><br></br><br></br>
                </Container>
            </section>
        </>
    );
}
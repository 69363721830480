import React from "react";
import {Navbar, Container,Nav, Image} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import './menu.css';
import imagen from "./assets/Logotipo.png"

export default function Menu(){
    return(
        <Navbar collapseOnSelect expand="lg"  className="menu" >
        <Container>
          <Navbar.Brand href="#home">
            <Image Src={imagen} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse className="justify-content-end">
          <Nav className="justify-content-end">
                <Nav.Item>
                    <Nav.Link href="#portadaInicio" className="lin" >Home</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="#Somos" className="lin">Somos</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="#Meta" className="lin">Meta</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="#Vision"  className="lin">Visión</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="#Propuestas"  className="lin">Propuesta</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="#Planes"  className="lin">Planes</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="#Contacto"  className="lin">Contacto</Nav.Link>
                </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

    );
}
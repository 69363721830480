import React from "react";
import {Container,Row,Image,Form,Button,FloatingLabel} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import imagen from './assets/busonimg.png';
import './contacto.css';

const color = {
    "background-color": "#FDF6F5",
}
const row ={
    "padding-bottom": "40px",
}

export default function Contacto(){
    return(
        <>
            <section style={color} id="Contacto">
                <Container>
                    <Row style={row}>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <Image className="img-fluid" src={imagen} />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <h4 className="privacidad">
                                La privacidad es nuestra prioridad
                            </h4><br></br>
                            <h2 className="buzon">
                                Buzón para denuncias.
                            </h2>
                            <p className="pBuzon">
                                En este buzón puedes tener orientación penal, poner quejas de acoso sexual, abusos laborales, bullying, etc.
                            </p>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <p className="pBuzon">
                            Nuestra estructura está desarrollada para canalizar estos delicados casos a los mejores expertos.
                            </p>
                        </div>
                    </Row>
                    <Form>
                    <Row>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                                <Form.Group>
                                    <Form.Label>Nombre</Form.Label>
                                    <Form.Control type="text" className="caja" />
                                </Form.Group>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                                <Form.Group >
                                    <Form.Label>Correo</Form.Label>
                                    <Form.Control type="email" className="caja" />
                                </Form.Group>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                                <Form.Group >
                                    <Form.Label>Teléfono</Form.Label>
                                    <Form.Control type="number" className="caja"  />
                                </Form.Group>
                        </div>
                    </Row><br></br>
                    <Row>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                                <Form.Group>
                                <FloatingLabel controlId="floatingTextarea2" label="Comentarios">
                                    <Form.Control
                                    as="textarea"
                                    placeholder="Leave a comment here"
                                    style={{ height: '100px', 'background-color': "transparent" }}
                                    />
                                </FloatingLabel>
                                </Form.Group>
                        </div>
                    </Row>
                    <br></br>
                    <Row>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="end">
                                <Button variant="danger" className="enviar" >Listo</Button>
                            </div>
                        </div>
                    </Row>
                    </Form>
                </Container>
            </section>
        </>
    );
}
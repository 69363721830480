import {Container,Row,Table,Thead,Tr,Th} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './privacidad.css';
const color ={
    color: "#DB3743"
}
const Privacidad = () =>{
    return(
        <>
                <header className='masthead'>
                <Container>
                    <Row>
                        <div className='col-lg-12 col-md-12 col-sm-12'>
                            <p className='headerT'>Aviso de privacidad</p>
                        </div>
                    </Row>
                </Container>
                </header>
                <section>
            
                    <Container>
                        <Row>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <p  className='pAviso'>
                                    SINDICATO DE TRABAJADORES DE LAS INDUSTRIAS ELABORADORAS DE PRODUCTOS ALIMENTICIOS, GATRONÓMICA,
                                    HOTELERA SUS SERVICIOS SIMILARES Y CONEXOS DE LA REPÚBLICA MEXICANA, organismo debidamente constituido
                                    conforme a la legislación mexicana con domicilio en Av. Altata número 7, Colonia Hipódromo Condesa,
                                    Alcaldía Cuauhtémoc, C.P. 06100, Ciudad de México., es responsable del tratamiento de sus datos
                                    personales, (denominada en lo sucesivo la <b style={color}>“Responsable”</b>), con fundamento en lo dispuesto en los
                                    artículos 15, 16 y 17 y demás correlativos y aplicables de la Ley de Protección de Datos Personales
                                    en Posesión de los Particulares y su Reglamento, pone a su disposición el presente aviso de
                                    privacidad en virtud de los datos personales que se pretenden recabar de usted como titular de los 
                                    mismos (denominado en lo sucesivo el <b style={color}>“Titular”</b>). 
                                </p>
                                <p className='pAviso'>
                                    Para la <b style={color}>Responsable</b>, es necesaria la recopilación de ciertos datos personales, lo anterior para llevar a cabo las actividades
                                    intrínsecas a su objeto social, por lo que tiene la obligación legal y social de cumplir con las medidas legales y de seguridad
                                    suficientes para proteger aquellos datos personales que haya recabado para las finalidades que en el presente aviso de privacidad
                                    serán descritas.
                                </p>
                                <p className='pAviso'>
                                    Todo lo anterior se realiza con el objetivo de que usted tenga pleno conocimiento, control y decisión
                                    sobre sus datos personales, por ello recomendamos que lea atentamente la siguiente información:
                                </p><br></br>
                                <div className='table-responsive'>
                                    <Table className='table table-responsive table-bordered' id="pTabla">
                                        <thead>
                                            <tr>
                                                <th>Departamento y/o responsable de privacidad</th>
                                                <th>Secretaría de Enlace</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Domicilio:</td>
                                                <td>Av. Altata número 7, Colonia Hipódromo Condesa, Alcaldía Cuauhtémoc, C.P. 06100, Ciudad de México.</td>
                                            </tr>
                                            <tr>
                                                <td>Correo electrónico:</td>
                                                <td>hola@sindicatodeamerica.com</td>
                                            </tr>
                                            <tr>
                                                <td>Teléfono:</td>
                                                <td>9983213154</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div><br></br>

                                <p className='pAviso'>
                                    <b style={color}>I.- ¿Para qué fines recabamos y utilizamos sus datos personales?</b><br></br><br></br>
                                    La <b style={color}>Responsable</b>, para la consecución de su objeto social, requiere prestar ciertos servicios al público, en ocasiones personas físicas de las que se requiere datos personales para la celebración de los contratos correspondientes. Sus datos personales serán utilizados para las siguientes finalidades:<br></br>
                                    
                                    1.- Para constatar la información proporcionada a: SINDICATO DE TRABAJADORES DE LAS INDUSTRIAS ELABORADORAS DE PRODUCTOS ALIMENTICIOS, GATRONÓMICA, HOTELERA SUS SERVICIOS SIMILARES Y CONEXOS DE LA REPÚBLICA MEXICANA.<br></br>
                                    
                                    2.- Dar cumplimiento a las obligaciones contractuales entre el <b style={color}>Titular y la Responsable.</b><br></br>
                                    
                                    3.- La realización por parte de la <b style={color}>Responsable</b>, de actividades propias, relacionadas y derivadas de su objeto social<br></br>
                                    
                                    4.- Modificar, en su caso, los contratos señalados.<br></br>
                                    
                                    5.- Elaborar estadísticas y reportes de los servicios prestados por la <b style={color}>Responsable</b> con el objeto de llevar un control interno de dichos servicios, así como para dar seguimiento puntual de los mismos.<br></br>
                                    
                                    6.- Cualquier finalidad análoga o compatible a las anteriores.<br></br>
                                    
                                    7.- Para cuestiones de carácter laboral y financiero donde serán exclusivamente para cumplir con los requisitos que marquen las autoridades laborales, financieras, fiscales y administrativas, de manera particular la Secretaría del Trabajo y Previsión Social, Procuradurías del Trabajo, Juntas Locales o Federales de Conciliación y Arbitraje, Instituto Mexicano del Seguro Social, INFONAVIT, SAT, SAR, Comisión Nacional Bancaria y de Valores, Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros, autoridades Federales, Estatales y Municipales o bien cuando sean solicitados por un mandato judicial, solo en dichos casos los datos podrán ser transferidos y tratados por personas distintas a ésta institución.<br></br>
                                    
                                    8.- Cumplir con todas las obligaciones a cargo de la <b style={color}>Responsable</b> que le imponga la legislación vigente.<br></br>
                                    
                                    9.- Para identificarlo y llevar a cabo los procesos de vinculación<br></br>
                                    
                                    10.- Contactarlo directamente o por medio de socios comerciales, durante el desarrollo e implementación de estrategias comerciales, de mercadotecnia o publicitarias para ofrecerle productos y/o servicios adicionales, finalidad que no es necesaria para el servicio solicitado pero que nos permiten y facilitan brindarle una mejor atención.
                                    
                                </p><br></br><br></br>
                                <p className='pAviso'>
                                    <b style={color}>II.- ¿Qué datos personales obtenemos y de dónde?</b><br></br><br></br>
                                    Para las finalidades señaladas en el presente aviso de privacidad, podemos recabar sus datos personales de distintas formas:<br></br>
                                </p>
                                <ul className='pAviso'>
                                    <li>Cuando usted como <b style={color}>Titular</b> nos lo proporciona directamente, a través de nuestros medios digitales</li>
                                    <li>Cuando un visitador por parte de la <b style={color}>Responsable</b>, lo visita a usted para verificar la veracidad de la información en el domicilio proporcionado por usted y/o</li>
                                    <li>Cuando obtenemos dicha información a través de otras fuentes de acceso público que están permitidas por la Ley Federal para la Protección de Datos Personales y su Reglamento.</li>
                                </ul>
                                <p className='pAviso'>
                                    <b style={color}>III.- Datos personales que recabamos de forma directa</b><br></br><br></br>
                                    La <b style={color}>Responsable</b>, para la consecución de su objeto social, requiere recabar ciertos datos personales, que pueden ser, entre otros:
                                </p>
                                <div class="table-responsive">
                                    <Table class="table table-responsive table-bordered" id="pTabla">
                                        <tbody>
                                            <tr>
                                                <td>Dato 1: Nombre completo </td>
                                            </tr>
                                            <tr>
                                                <td>Dato 2: Correo electrónico</td>
                                            </tr>
                                            <tr>
                                                <td>Dato 3: Número telefónico (fijo y/o celular)</td>
                                            </tr>
                                            <tr>
                                                <td>Dato 4: Perfil en Redes Sociales</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                                <p className='pAviso'>
                                    Su información puede ser compartida con, sociedades filiales al grupo al que pertenece el Responsable y su personal autorizado, que para los fines del servicio contratado sea necesario, asimismo para fines informativos, promocionales, publicitarios o mercantiles.<br></br>

                                    Sus datos personales no serán transmitidos sin su consentimiento, con la excepción de las sociedades subsidiarias, filiales y/o controladoras.<br></br>

                                    Le informamos que en nuestra página de internet utilizamos cookies, web beacons y otras tecnologías a través de las cuales es posible monitorear su comportamiento como usuario de internet, brindarte un mejor servicio y experiencia de usuario al navegar en nuestra página, así como ofrecerle nuevos productos y servicios basados en tus preferencias. Los datos personales obtenidos de estas tecnologías de rastreo son los siguientes: dirección de Protocolo de Internet (IP) de su dispositivo, dirección IP de su proveedor de servicios de internet, resolución de pantalla del dispositivo, tipo de dispositivo (identificadores de dispositivo únicos), navegador y versiones del sistema operativo, ubicación geográfica (solo país), idioma preferido utilizado para mostrar fecha y hora de acceso al sitio web, dirección de internet desde la que se vincula directamente a nuestro sitio web (www.sindicatoamerica.com y sus subdominios), el sistema de control que usa las partes del sitio web que visita, las páginas del sitio web que ha visitado y la información que ha visto, los hipervínculos en los que ha hecho “click”, información sobre los tipos de electrodomésticos que usó para su visita al sitio y el material que envía o descarga de nuestro sitio web.<br></br>
                                    Asimismo, si elige descargar nuestros informes o documentos; o suscribirte a nuestro boletín de noticias, eventos y alertas; o enviar una consulta, el Responsable puede solicitarle que complete un formulario con información tal como: su nombre, dirección de correo electrónico, cargo y compañía. A partir de este punto, el Responsable podrá relacionar la información enumerada anteriormente sobre su dispositivo, su navegador y la forma en que navega por el contenido de nuestro sitio web directamente a usted.
                                </p>
                                <p className='pAviso'>
                                    <b style={color}>IV.- Datos personales sensibles.</b><br></br><br></br>
                                    Hacemos de su conocimiento que la Responsable recaba Datos Personales Sensibles, por lo que de conformidad con lo que establecen los artículos 9 y 13 de la Ley en cita, requerimos de su consentimiento expreso para el tratamiento de sus datos personales sensibles así como patrimoniales y financieros.<br></br><br></br>
                                    Nos comprometemos a que los mismos serán tratados bajo las más estrictas medidas de seguridad que garanticen su confidencialidad.<br></br>
                                    <br></br>
                                    <b style={color}>V.- Opciones y Medios que la Responsable ofrece para limitar el uso o divulgación de datos personales:</b><br></br><br></br>
                                    Con el objeto de limitar el uso o divulgación de los datos personales así como datos personales sensibles, la Responsable resguarda dichos datos personales bajo programas de cómputo con acceso limitado mediante el uso de contraseñas con altas especificaciones de seguridad, a los cuales tiene acceso únicamente el personal con facultades para ello. Por lo que respecta a documentos físicos que contengan datos personales del Titular, la Responsable los resguarda en archivos bajo llave, a los cuales únicamente tendrá acceso únicamente el personal con facultades para ello.<br></br>

                                    Asimismo, con el fin de limitar el uso o divulgación de los datos personales contenidos en medios electrónicos y documentos físicos, la Responsable utiliza todas y cada una de las medidas de seguridad que se establece en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y en su Reglamento.<br></br>

                                    Finalmente, la Responsable ha implementado capacitación, así como políticas y procesos internos aplicables a su personal, a través de los cuales los datos personales son utilizados con estricta confidencialidad por un mínimo de personas, limitándose el uso de los medios de reproducción, y generándose la obligación de destruir todas aquellas copias o reproducciones de documentos que contengan datos personales que no sean estrictamente indispensables para el adecuado desempeño de las funciones del personal de la Responsable, así como la prohibición de extraer de las oficinas de la Responsable cualquier tipo de información que contenga datos personales.<br></br>
                                    <br></br>
                                    <b style={color}>VI.- ¿Cómo acceder, rectificar o revocar sus datos personales o cancelar u oponerse a su uso?</b><br></br><br></br>
                                    La Titular tendrá el derecho de acceso para acceder a sus datos personales que posee la Responsable, y a los detalles del tratamiento de los mismos; rectificación para poder rectificarlos en caso de ser inexactos o incompletos; cancelación para cancelarlos cuando considere que no se requieren para alguna de las finalidades señalados en el presente aviso de privacidad o que estén siendo utilizados para finalidades no consentidas o haya finalizado la relación contractual o de servicio, o bien, de oposición para oponerse al tratamiento de los mismos, todo esto con fundamento en el artículo 22 de la citada Ley. Por lo tanto, el mecanismo para poder llevar a cabo el ejercicio de dichos derechos, será a través de la presentación de una solicitud, por escrito en términos del artículo 29 de la ley en cita con firma autógrafa de su puño y letra en el domicilio de la Responsable dirigida a la persona encargada de la Dirección Comercial señalada en la tabla a que se refiere el principio del presente aviso, persona a través de la cual se le dará trámite a la solicitud, con acuse de recibo del escrito correspondiente, o bien podrá dirigir la solicitud a la dirección de correo electrónico señalada en la tabla referida al principio del presente aviso. La Responsable comunicará al Titular en el domicilio proporcionado por éste última o a través de correo electrónico, en un plazo máximo de 20 (veinte) días, prorrogables en términos de ley, contados a partir de la fecha de recepción de la solicitud, la determinación adoptada, para todos los efectos legales a que haya lugar.<br></br>
                                    Su solicitud de acceso, rectificación, cancelación u oposición (ARCO), ya sea por escrito o por correo electrónico, deberá contener la siguiente información:<br></br>
                                </p>
                                <ul className='pAviso' >
                                    <li>El Nombre del <b style={color}>Titular</b> y domicilio u otro medio para comunicarte la respuesta a su solicitud;</li>
                                    <li>Los documentos que acrediten la identidad, o en su caso, la representación legal.</li>
                                    <li>La descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los derechos antes mencionados, y</li>
                                    <li>Cualquier otro elemento o documento que facilite la localización de los datos personales.</li>
                                </ul>
                                <p className='pAviso'>
                                    <b style={color}>VII.- Transferencia de datos</b><br></br><br></br>
                                    La Responsable comunicará al Titular mediante el presente aviso que sus datos pueden ser transferidos a terceros nacionales o extranjeros de otro país cuando: a) decida ceder a un tercero, mediante cualquier forma legal, los derechos y/u obligaciones que tenga frente al Titular; Por lo tanto, el Titular consiente que sus datos personales pueden ser transferidos y tratados dentro y fuera del país, a efecto de estar en posibilidad de cumplir con los objetivos lícitos de la Responsable.<br></br>
                                    
                                    Sobre este particular, el Titular acepta que en este supuesto, no se requerirá de su consentimiento de acuerdo a las excepciones previstas en el artículo 37 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y su Reglamento, por lo que la Responsable realizará esta transferencia para las finalidades señaladas en el presente apartado en los términos que fija la ley y su Reglamento.<br></br>
                                    
                                    En todos los casos, la Responsable dará a conocer a los terceros el contenido del presente aviso de privacidad.<br></br><br></br>
                                    
                                    <b style={color}>VIII.- Modificaciones al aviso de privacidad</b><br></br><br></br>
                                    La Responsable, se reserva el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente aviso de privacidad, para la atención de novedades legislativas, políticas internas o nuevos requerimientos para la prestación u ofrecimiento de nuestros servicios o productos.<br></br>
                                    
                                    Estas modificaciones estarán disponibles al público y la hará del conocimiento del Titular a efecto de obtener su consentimiento respecto del nuevo tratamiento de sus datos personales, lo anterior se realizará poniendo a su disposición el nuevo aviso de privacidad a través de los siguientes medios:<br></br>

                                </p>
                                <ul className='pAviso'>
                                    <li>Anuncios visibles en nuestros establecimientos o centros de atención a clientes;</li>
                                    <li>Trípticos o folletos disponibles en nuestros establecimientos o centros de atención a clientes;</li>
                                    <li>Se las haremos llegar al último correo electrónico que nos haya proporcionado a criterio de la <b style={color}>Responsable</b>.</li>
                                </ul>
                                <p className='pAviso'>
                                    Aplicando en lo conducente lo establecido en la propia Ley Federal de Protección de Datos Personales en Posesión de los Particulares y su Reglamento, en su caso, para la manifestación de la voluntad del Titular, lo cual aplicará únicamente para los aspectos del tratamiento de datos personales diversos de los ya consentidos para este aviso de privacidad.<br></br><br></br>
                                    <b style={color}>IX.- ¿Ante quién puede presentar sus quejas y denuncias por el tratamiento indebido de sus datos personales?</b><br></br><br></br>
                                    Si usted considera que alguno de sus derechos de protección de datos personales ha sido lesionado por alguna conducta de nuestros empleados o de nuestras actuaciones o respuestas, o presume que en el tratamiento de sus datos personales existe alguna violación a las disposiciones previstas en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, podrá solicitar apoyo ante el Instituto Federal de Acceso a la Información Pública y Protección de Datos (IFAI).<br></br><br></br>
                                    <center><b style={color}>CONSENTIMIENTO DEL TITULAR</b></center><br></br>
                                    Con fundamento en el artículo 8 de la Ley Federal de Datos Personales en Posesión de los Particulares, consiento en entregar a la Responsable, mis datos personales a efecto de que sean tratados conforme a lo consignado en el aviso de privacidad que fue puesto a mi disposición y consiento en lo siguiente:<br></br>
                                </p>
                                <ul className='pAviso'>
                                    <li>Consiento el manejo de Datos Personales</li>
                                    <li>Consiento el manejo de datos sensibles</li>
                                    <li>Consiento la transferencia internacional de información personal y sensible</li>
                                    <li>Estoy enterado de cómo realizar modificaciones, cancelaciones y/o revisión de mi información</li>
                                </ul>
                                <p className='pAviso'>
                                    Asimismo, con fundamento en el segundo párrafo del artículo 35 del ordenamiento señalado, acepto la transferencia de mis datos personales a los terceros que resulten necesarios conforme a lo indicado en el aviso de privacidad.<br></br>
                                
                                    El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales, de nuestras propias necesidades y de nuestras prácticas de protección de datos personales. Randstad le mantendrá informado de lo anterior, poniendo a su disposición el nuevo aviso de privacidad en la página de internet <a style={color} href="aviso-privacidad.html">https://www.sindicatodeamercia.com/</a> <br></br>
                                
                                    Al no oponerme a la presente, autorizo el tratamiento y uso de mis datos personales en términos del presente Aviso de Privacidad.<br></br>
                                
                                    Fecha de la última actualización al presente aviso de privacidad: 26 de abril de 2021.
                                </p>

                            </div>
                        </Row>
                    </Container>
                </section>
        </>
    );
}

export default Privacidad
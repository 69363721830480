import React from "react";
import {Container,Row} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./footer.css";
import {
  Link
} from "react-router-dom";

const color ={
    color: "#DB3743",
}
export default function Footer(){
    return(
        <footer className="footer py-4">
            <Container>
                <Row className="rowRedes">
                    <div class="col-lg-4 my-4 my-lg-0">
                        <p className="pfooter">
                        Escríbenos a hola@sindicatoamerica.com o por Messenger de Facebook
                        </p>
                    </div>
                    <div class="col-lg-2 my-2 my-lg-0 col-md-4">
                        <a className="pfooter"  href="https://www.facebook.com/SDAsindicato/" target="__bank">
                            Facebook
                        </a>
                    </div>
                    <div class="col-lg-2 my-2 my-lg-0 col-md-4">
                        <a className="pfooter" href="https://www.instagram.com/p/CMAa31pj9GP/?igshid=p3s1u78x7ccb" target="__bank">
                            Instagram
                        </a>
                    </div>
                    <div class="col-lg-2 my-2 my-lg-0 col-md-4">
                        <a className="pfooter" href="https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2F76322443%2Fadmin%2F" target="__bank">
                            Linkedin
                        </a>
                    </div>
                </Row>
                <Row>
                    <div className="col-lg-12 my-12 my-lg-0">
                        <hr className="liena"></hr>
                    </div>
                </Row>
                <Row>
                    <div className="col-lg-12 my-12 my-lg-0">
                        <p className="footerp">
                        Copyright © 2021 Sindicato de América. | <Link className="footerp" to="/aviso"><b style={color}>Aviso de Privacidad.</b></Link>
                        </p>
                    </div>
                </Row>
            </Container>
        </footer>
    );
}
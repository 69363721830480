import {Container,Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './orrganizacio.css';

const color = {
    color: "#DB3743"
}

export default function Organizacion(){
    
    return(
        <>
            <section className="secOrg">
                <Container>
                    <Row>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <h3 className="hOrganizacion">
                                Nuestra Organización
                            </h3>
                            <h3 className="h3Organizacion">
                                Está integrada por <b style={color}>veinticinco secretarías las cuales tienen un periodo de tres años y los secretarios</b> fueron electos
                                por votación cerrada y apegado a la ley federal del trabajo.
                            </h3>
                        </div>
                    </Row>
                </Container>
            </section>

        </>
    );
}
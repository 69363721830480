import React from "react";
import {Container,Row,Image} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './planes.css';
import caja from './assets/cajaicon.png';
import dian from './assets/diagnosticoicon.png';
import seg from './assets/segurosicon.png';
import fondo from './assets/fondosicon.png';
import seguro from './assets/seguros2icon.png';
import inver from './assets/inverionesicon.png';
import gastos from './assets/gastosicon.png';
import atenc from './assets/atencionicon.png';
import vivienda from './assets/viviendaicon.png';
import capacitacion from './assets/capacitacionesicon.png';
import cursos from './assets/cursosicon.png';
import inde from './assets/indemnizacionicon.png';
import entre from './assets/entretenimientoicon.png';
import alimen from './assets/alimentosicon.png';
import asisten from './assets/asistenciaicon.png';
import transporte from './assets/transporteicon.png';
import planes from './assets/planesiconprivados.png';
import img from './assets/imgplanes.png';

export default function Planes(){
    return(
        <>
            <section className="secPlanes" id="Planes">
                <Container>
                    <Row className="PlnImg" >
                        <Image src={img} className="img-fluid"/>
                    </Row>
                    <Row className="PnlTitulo">
                        <div className="col-lg-12 col-md-12 col-lg-12">
                            <h2 className="hPlanes">Planes de productividad</h2>
                            <h4 className="pPlanes">Observamos la necesidades y creamos planes.</h4>
                        </div>
                    </Row><br></br>
                    <Row className="rowPlanes" >
                        <div className="col-lg-3 col-md-3 col-sm,-12">  
                            <Image src={seguro} className="img-fluid" id="imgPlanes" />
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12">  
                            <Image src={inver} className="img-fluid" id="imgPlanes" />
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12">  
                            <Image src={gastos} className="img-fluid" id="imgPlanes" />
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12">  
                            <Image src={atenc} className="img-fluid" id="imgPlanes" />
                        </div>
                    </Row>
                    <Row className="rowPlanes">
                        <div className="col-lg-3 col-md-3 col-sm,-12">  
                            <Image src={caja} className="img-fluid" id="imgPlanes" />
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12">  
                            <Image src={dian} className="img-fluid" id="imgPlanes" />
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12">  
                            <Image src={seg} className="img-fluid" id="imgPlanes" />
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12">  
                            <Image src={fondo} className="img-fluid" id="imgPlanes" />
                        </div>
                    </Row>
                    <Row className="rowPlanes">
                        <div className="col-lg-3 col-md-3 col-sm,-12">  
                            <Image src={vivienda} className="img-fluid" id="imgPlanes" />
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12">  
                            <Image src={capacitacion} className="img-fluid" id="imgPlanes" />
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12">  
                            <Image src={cursos} className="img-fluid" id="imgPlanes" />
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12">  
                            <Image src={inde} className="img-fluid" id="imgPlanes" />
                        </div>
                    </Row>
                    <Row className="rowPlanes">
                        <div className="col-lg-3 col-md-3 col-sm,-12">  
                            <Image src={entre} className="img-fluid" id="imgPlanes" />
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12">  
                            <Image src={alimen} className="img-fluid" id="imgPlanes" />
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12">  
                            <Image src={asisten} className="img-fluid" id="imgPlanes" />
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12">  
                            <Image src={transporte} className="img-fluid" id="imgPlanes" />
                        </div>
                    </Row>
                    <Row className="rowPlanes">
                        <div className="col-lg-3 col-md-3 col-sm,-12">  
                            <Image src={planes} className="img-fluid" id="imgPlanes" />
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    );
}
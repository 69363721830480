import React, { useState } from 'react';
import {Container,Row, Image,Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './orrganizacio.css';
import kino from './assets/Kino.png';
import Pablo from './assets/JPablo.png';
import Ivan from './assets/IvanC.png';
import Adriano from './assets/Adriano.png';
import Gabi from './assets/GabrielaCortes.png';
import Arturo from './assets/ArturoParra.png';
import Oscar from './assets/Oscar.png';
import Aust from './assets/Austreberto.png';
import Mario from './assets/MarioC.png';
import Valeria from './assets/Valeria.png';
import Ricardo from './assets/RicardoVargas.png';
import Guillermo from './assets/GuillermoVergara.png';
import Daniela from './assets/DanielaBaeza.png';
import Bernardo from './assets/Bernardo.png';
import Cinthia from './assets/Cinthya.png';
import Jose from './assets/JLuisMoreno.png';
import Alondra from './assets/AlondraH.png';
import Isael from './assets/IsraelE.png';
import Cua from './assets/Cuauhtemoc.png';
import Marcos from './assets/MarcosFalfan.png';
import Javier from './assets/JavierGuerra.png';
import Michell from './assets/MichelleZ.png';
import Claudia from './assets/ClauidaZ.png';
import KinoP from './assets/KinoP.png';
import JuanPP from './assets/JuanPabloP.png';
import IvanP from './assets/IvanP.png';
import AdrianoP from './assets/AdrianoP.png';

import GabiP from './assets/GabrielaP.png';
import ArturoP from './assets/ArturoP.png';
import OscarP from './assets/Oscar_slideP.png';
import AustP from './assets/austrebertoP.png';
import MarioP from './assets/MarioP.png';
import ValeriaP from './assets/ValeriaP.png';
import RicardoP from './assets/RicardoP.png';
import GuillermoP from './assets/GuillermoP.png';
import DanielaP from './assets/DannielaP.png';
import BernardoP from './assets/BernardoP.png';
import CinthiaP from './assets/CinthyaP.png';
import JoseP from './assets/JluisP.png';
import AlondraP from './assets/AlondraP.png';
import IsaelP from './assets/IsraelP.png';
import CuaP from './assets/CuahutemocP.png';
import MarcoP from './assets/FalfanP.png';
import JavierP from './assets/JavierP.png';
import MichellP from './assets/MichelleP.png';
import ClaudiaP from './assets/ClaudiaP.png';


export default function Organizacion(){
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);

    const [show5, setShow5] = useState(false);
    const handleClose5 = () => setShow5(false);
    const handleShow5 = () => setShow5(true);

    const [show6, setShow6] = useState(false);
    const handleClose6 = () => setShow6(false);
    const handleShow6 = () => setShow6(true);

    const [show7, setShow7] = useState(false);
    const handleClose7 = () => setShow7(false);
    const handleShow7 = () => setShow7(true);

    const [show8, setShow8] = useState(false);
    const handleClose8 = () => setShow8(false);
    const handleShow8 = () => setShow8(true);

    const [show9, setShow9] = useState(false);
    const handleClose9 = () => setShow9(false);
    const handleShow9 = () => setShow9(true);

    const [show10, setShow10] = useState(false);
    const handleClose10 = () => setShow10(false);
    const handleShow10 = () => setShow10(true);

    const [show11, setShow11] = useState(false);
    const handleClose11 = () => setShow11(false);
    const handleShow11 = () => setShow11(true);

    const [show12, setShow12] = useState(false);
    const handleClose12 = () => setShow12(false);
    const handleShow12 = () => setShow12(true);

    const [show13, setShow13] = useState(false);
    const handleClose13 = () => setShow13(false);
    const handleShow13 = () => setShow13(true);

    const [show14, setShow14] = useState(false);
    const handleClose14 = () => setShow14(false);
    const handleShow14 = () => setShow14(true);

    const [show15, setShow15] = useState(false);
    const handleClose15 = () => setShow15(false);
    const handleShow15 = () => setShow15(true);

    const [show16, setShow16] = useState(false);
    const handleClose16 = () => setShow16(false);
    const handleShow16 = () => setShow16(true);

    const [show17, setShow17] = useState(false);
    const handleClose17 = () => setShow17(false);
    const handleShow17 = () => setShow17(true);

    const [show18, setShow18] = useState(false);
    const handleClose18 = () => setShow18(false);
    const handleShow18 = () => setShow18(true);

    const [show19, setShow19] = useState(false);
    const handleClose19 = () => setShow19(false);
    const handleShow19 = () => setShow19(true);

    const [show20, setShow20] = useState(false);
    const handleClose20 = () => setShow20(false);
    const handleShow20 = () => setShow20(true);

    const [show21, setShow21] = useState(false);
    const handleClose21 = () => setShow21(false);
    const handleShow21 = () => setShow21(true);

    const [show22, setShow22] = useState(false);
    const handleClose22 = () => setShow22(false);
    const handleShow22 = () => setShow22(true);

    const [show23, setShow23] = useState(false);
    const handleClose23 = () => setShow23(false);
    const handleShow23 = () => setShow23(true);
    
    return(
        <>
            <section className="secOrgImg">
                <Container>
                    <Row>
                        <div className="col-lg-3 col-md-3 col-sm-3 center">
                            <Image src={kino}  /><br></br><br></br>
                            <p className="NombreTitulo">Kino Miquirray</p>
                            <p className="Puesto">Secretario General <br></br> <a className="aResena" href='##' onClick={handleShow}>Ver Reseña +</a></p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 center">
                            <Image src={Pablo}  /><br></br><br></br>
                            <p className="NombreTitulo">Juan Pablo Miquirray</p>
                            <p className="Puesto">Secretario de Actas <br></br> <a className="aResena" href="##" onClick={handleShow2}>Ver Reseña +</a></p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 center">
                            <Image src={Ivan}  /><br></br><br></br>
                            <p className="NombreTitulo">Iván Carrasco</p>
                            <p className="Puesto">Tesorero <br></br> <a className="aResena" href="##" onClick={handleShow3} >Ver Reseña +</a></p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 center">
                            <Image src={Adriano}  /><br></br><br></br>
                            <p className="NombreTitulo">Adriano Numa</p>
                            <p className="Puesto">Secretario Derechos Humanos<br></br> <a className="aResena" href="##" onClick={handleShow4}>Ver Reseña +</a></p>
                        </div>
                    </Row><br></br>
                    <Row>
                        <div className="col-lg-3 col-md-3 col-sm-3 center">
                            <Image src={Gabi}  /><br></br><br></br>
                            <p className="NombreTitulo">Gabriela Cortés</p>
                            <p className="Puesto">Secretaria de Conflictos<br></br> <a className="aResena"  href="##" onClick={handleShow5}>Ver Reseña +</a></p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 center">
                            <Image src={Arturo}  /><br></br><br></br>
                            <p className="NombreTitulo">Arturo Parra</p>
                            <p className="Puesto">Secretaría de Secciones Sindicales <br></br> <a className="aResena"  href="##" onClick={handleShow6}>Ver Reseña +</a></p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 center">
                            <Image src={Oscar}  /><br></br><br></br>
                            <p className="NombreTitulo">Oscar Hernandez</p>
                            <p className="Puesto">Secretario de Interior y Propaganda. <br></br> <a className="aResena"  href="##" onClick={handleShow7}>Ver Reseña +</a></p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 center">
                            <Image src={Aust}  /><br></br><br></br>
                            <p className="NombreTitulo">Austreberto Miquirray</p>
                            <p className="Puesto">Secretario de Cultura<br></br> <a className="aResena"  href="##" onClick={handleShow8}>Ver Reseña +</a></p>
                        </div>
                    </Row><br></br>
                    <Row>
                        <div className="col-lg-3 col-md-3 col-sm-3 center">
                            <Image src={Mario}  /><br></br><br></br>
                            <p className="NombreTitulo">Mario Cabrera</p>
                            <p className="Puesto">Secretario de Bienestar <br></br> <a className="aResena" href="##" onClick={handleShow9}>Ver Reseña +</a></p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 center">
                            <Image src={Valeria}  /><br></br><br></br>
                            <p className="NombreTitulo">Valeria González</p>
                            <p className="Puesto">Secretaria de Acción Femenil<br></br> <a className="aResena" href="##" onClick={handleShow10}>Ver Reseña +</a></p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 center">
                            <Image src={Ricardo}  /><br></br><br></br>
                            <p className="NombreTitulo">Ricardo Vargas</p>
                            <p className="Puesto">Secretario de Innovación Tecnológica<br></br> <a className="aResena" href="##" onClick={handleShow11}>Ver Reseña +</a></p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 center">
                            <Image src={Guillermo}  /><br></br><br></br>
                            <p className="NombreTitulo">Guillermo Vergara</p>
                            <p className="Puesto">Secretario de Turismo<br></br> <a className="aResena" href="##" onClick={handleShow12}>Ver Reseña +</a></p>
                        </div>
                    </Row><br></br>
                    <Row>
                        <div className="col-lg-3 col-md-3 col-sm-3 center">
                            <Image src={Daniela}  /><br></br><br></br>
                            <p className="NombreTitulo">Daniela Baeza</p>
                            <p className="Puesto">Secretaria de Prensa<br></br> <a className="aResena" href="##" onClick={handleShow13}>Ver Reseña +</a></p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 center">
                            <Image src={Bernardo}  /><br></br><br></br>
                            <p className="NombreTitulo">Bernardo Mendez Lugo</p>
                            <p className="Puesto">Secretario de Asuntos Multinacionales<br></br> <a className="aResena" href="##" onClick={handleShow14}>Ver Reseña +</a></p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 center">
                            <Image src={Cinthia}  /><br></br><br></br>
                            <p className="NombreTitulo">Cinthya Castillo</p>
                            <p className="Puesto">Secretaria de Capacitación y Desarrollo<br></br> <a className="aResena" href="##" onClick={handleShow15}>Ver Reseña +</a></p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 center">
                            <Image src={Jose}  /><br></br><br></br>
                            <p className="NombreTitulo">Jose Luis Moreno</p>
                            <p className="Puesto">Secretario de Previsión Social <br></br> <a className="aResena" href="##" onClick={handleShow16}>Ver Reseña +</a></p>
                        </div>
                    </Row><br></br>
                    <Row>
                        <div className="col-lg-3 col-md-3 col-sm-3 center">
                            <Image src={Alondra}  /><br></br><br></br>
                            <p className="NombreTitulo">Alondra Hernández</p>
                            <p className="Puesto">Secretaria del Trabajo<br></br> <a className="aResena" href="##" onClick={handleShow17} >Ver Reseña +</a></p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 center">
                            <Image src={Isael}  /><br></br><br></br>
                            <p className="NombreTitulo">Isael Elizondo</p>
                            <p className="Puesto">Secretario de Asuntos Políticos<br></br> <a className="aResena" href="##" onClick={handleShow18}>Ver Reseña +</a></p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 center">
                            <Image src={Cua}  /><br></br><br></br>
                            <p className="NombreTitulo">Cuauhtémoc Ríos</p>
                            <p className="Puesto">Secretario de Medio Ambiente y Sustentabilidad<br></br> <a className="aResena" href="##" onClick={handleShow19}>Ver Reseña +</a></p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 center">
                            <Image src={Marcos}  /><br></br><br></br>
                            <p className="NombreTitulo">Marcos Falfan</p>
                            <p className="Puesto">Secretario de Pueblos Originarios y Acción Social<br></br> <a className="aResena" href="##" onClick={handleShow20}>Ver Reseña +</a></p>
                        </div>
                    </Row><br></br>
                    <Row>
                        <div className="col-lg-3 col-md-3 col-sm-3 center">
                            <Image src={Javier}  /><br></br><br></br>
                            <p className="NombreTitulo">Javier Guerra</p>
                            <p className="Puesto">Secretaria de Exterior<br></br> <a className="aResena" href="##" onClick={handleShow21}>Ver Reseña +</a></p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 center">
                            <Image src={Michell}  /><br></br><br></br>
                            <p className="NombreTitulo">Michelle Zaragoza</p>
                            <p className="Puesto">Cómité de Honor y Justicia<br></br> <a className="aResena" href="##" onClick={handleShow22}>Ver Reseña +</a></p>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 center">
                            <Image src={Claudia}  /><br></br><br></br>
                            <p className="NombreTitulo">Claudia Zaragoza</p>
                            <p className="Puesto">Comité de Honor y Justicia<br></br> <a className="aResena" href="##" onClick={handleShow23}>Ver Reseña +</a></p>
                        </div>
                    </Row>
                </Container>
            </section>
            <Modal show={show} onHide={handleClose}
                        size="lg"
                        centered
                        
                >
                <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image src={KinoP} className="img-fluid mdl"></Image>
                </Modal.Body>
            </Modal>
            <Modal show={show2} onHide={handleClose2}
                        size="lg"
                        centered
                        
                >
                <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image src={JuanPP} className="img-fluid mdl"></Image>
                </Modal.Body>
            </Modal>
            <Modal show={show3} onHide={handleClose3}
                        size="lg"
                        centered
                        
                >
                <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image src={IvanP} className="img-fluid mdl"></Image>
                </Modal.Body>
            </Modal>
            <Modal show={show4} onHide={handleClose4}
                        size="lg"
                        centered
                        
                >
                <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image src={AdrianoP} className="img-fluid mdl"></Image>
                </Modal.Body>
            </Modal>
            <Modal show={show5} onHide={handleClose5}
                        size="lg"
                        centered
                        
                >
                <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image src={GabiP} className="img-fluid mdl"></Image>
                </Modal.Body>
            </Modal>
            <Modal show={show6} onHide={handleClose6}
                        size="lg"
                        centered
                        
                >
                <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image src={ArturoP} className="img-fluid mdl"></Image>
                </Modal.Body>
            </Modal>
            <Modal show={show7} onHide={handleClose7}
                        size="lg"
                        centered
                        
                >
                <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image src={OscarP} className="img-fluid mdl"></Image>
                </Modal.Body>
            </Modal>
            <Modal show={show8} onHide={handleClose8}
                        size="lg"
                        centered
                        
                >
                <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image src={AustP} className="img-fluid mdl"></Image>
                </Modal.Body>
            </Modal>

            <Modal show={show9} onHide={handleClose9}
                        size="lg"
                        centered
                        
                >
                <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image src={MarioP} className="img-fluid mdl"></Image>
                </Modal.Body>
            </Modal>
            <Modal show={show10} onHide={handleClose10}
                        size="lg"
                        centered
                        
                >
                <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image src={ValeriaP} className="img-fluid mdl"></Image>
                </Modal.Body>
            </Modal>
            <Modal show={show11} onHide={handleClose11}
                        size="lg"
                        centered
                        
                >
                <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image src={RicardoP} className="img-fluid mdl"></Image>
                </Modal.Body>
            </Modal>
            <Modal show={show12} onHide={handleClose12}
                        size="lg"
                        centered
                        
                >
                <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image src={GuillermoP} className="img-fluid mdl"></Image>
                </Modal.Body>
            </Modal>
            <Modal show={show13} onHide={handleClose13}
                        size="lg"
                        centered
                        
                >
                <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image src={DanielaP} className="img-fluid mdl"></Image>
                </Modal.Body>
            </Modal>
            <Modal show={show14} onHide={handleClose14}
                        size="lg"
                        centered
                        
                >
                <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image src={BernardoP} className="img-fluid mdl"></Image>
                </Modal.Body>
            </Modal>
            <Modal show={show15} onHide={handleClose15}
                        size="lg"
                        centered
                        
                >
                <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image src={CinthiaP} className="img-fluid mdl"></Image>
                </Modal.Body>
            </Modal>
            <Modal show={show16} onHide={handleClose16}
                        size="lg"
                        centered
                        
                >
                <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image src={JoseP} className="img-fluid mdl"></Image>
                </Modal.Body>
            </Modal>
            <Modal show={show17} onHide={handleClose17}
                        size="lg"
                        centered
                        
                >
                <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image src={AlondraP} className="img-fluid mdl"></Image>
                </Modal.Body>
            </Modal>
            <Modal show={show18} onHide={handleClose18}
                        size="lg"
                        centered
                        
                >
                <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image src={IsaelP} className="img-fluid mdl"></Image>
                </Modal.Body>
            </Modal>
            <Modal show={show19} onHide={handleClose19}
                        size="lg"
                        centered
                        
                >
                <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image src={CuaP} className="img-fluid mdl"></Image>
                </Modal.Body>
            </Modal>
            <Modal show={show20} onHide={handleClose20}
                        size="lg"
                        centered
                        
                >
                <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image src={MarcoP} className="img-fluid mdl"></Image>
                </Modal.Body>
            </Modal>
            <Modal show={show21} onHide={handleClose21}
                        size="lg"
                        centered
                        
                >
                <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image src={JavierP} className="img-fluid mdl"></Image>
                </Modal.Body>
            </Modal>
            <Modal show={show22} onHide={handleClose22}
                        size="lg"
                        centered
                        
                >
                <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image src={MichellP} className="img-fluid mdl"></Image>
                </Modal.Body>
            </Modal>
            <Modal show={show23} onHide={handleClose23}
                        size="lg"
                        centered
                        
                >
                <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Image src={ClaudiaP} className="img-fluid mdl"></Image>
                </Modal.Body>
            </Modal>
        </>
    );
}
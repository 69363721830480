import React from "react";
import {Container,Row,Image} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import imagen from './assets/imgvision.png';
import imagen2 from './assets/imgplantea.png';
import './vision.css';

const negritas ={
    color: "#494949"
}
const rojo ={
    color: "#DB3743"
}
const gris = {
    color: "#898989"
}

export default function Vision(){
    return(
        <>
            <section id="Vision">
                <Container>
                    <Row>
                        <Image src={imagen}  />
                    </Row><br></br><br></br>
                    <Row>
                        <div className="col-lg-7 col-md-6 col-sm-6" id="dSomos">
                            <h4 className="Somos">
                                ¿Nuestra Visión?
                            </h4>
                            <h3>
                                <b style={negritas}>La empatía humana es uno de nuestros pilares, basada en el respeto</b> a la raza, 
                                idioma, religión y cultural del otro.
                            </h3>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12">
                            <p className="parrafo">
                                <br></br><br></br>
                                La empatía nos hace receptores dignos de las necesidades de nuestros agremiados.<br></br> 
                                Consideramos que <b style={rojo}>la relación sindicato, empresa y agremiado debe replantearse para transformar 
                                la negociación</b> de los contratos colectivos de trabajo, las prestaciones sociales y sus beneficios.
                            </p>
                        </div>
                    </Row>
                </Container>
            </section>
            <section id="section-gris">
                <Container>
                    <Row>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <Image src={imagen2} className="img-fluid" />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <h3 id="tex">
                                <b style={gris}>El planteamiento de la productividad y los beneficios</b> debe ser equitativa para las tres partes.
                            </h3>
                        </div>
                    </Row>
                </Container>
            </section>
        </>
    );
}